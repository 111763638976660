import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import KeywordList from "./KeywordList";
import axios from "axios";
import ProgressWebSocket from "./ProgressWebSocket";

const KeywordSelectionPage: React.FC = () => {
  const [keywords, setKeywords] = useState<string[]>([]);
  const [selectedKeywords, setSelectedKeywords] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [scrapingInProgress, setScrapingInProgress] = useState<boolean>(true);
  const navigate = useNavigate();
  const location = useLocation();
  const { spiderId } = location.state;

  useEffect(() => {
    // Fetch keywords from backend using the key
    const fetchKeywords = async () => {
      const token = localStorage.getItem("token");
      const axiosConfig = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/demo/keywords?spiderId=${spiderId}`,
          axiosConfig
        );
        setKeywords(response.data.keywords);
      } catch (error) {
        console.error("Error fetching keywords:", error);
      } finally {
        setLoading(false);
      }
    };
    if (scrapingInProgress) {
      return;
    }
    fetchKeywords();
  }, [scrapingInProgress]);

  const handleKeywordChange = (keyword: string) => {
    setSelectedKeywords((prevSelected) =>
      prevSelected.includes(keyword)
        ? prevSelected.filter((k) => k !== keyword)
        : [...prevSelected, keyword]
    );
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    // Redirect to Result Page
    navigate("/results", { state: { selectedKeywords } });
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      {scrapingInProgress || loading ? (
        <ProgressWebSocket
          spiderId={spiderId}
          setScrapingInProgress={setScrapingInProgress}
        />
      ) : (
        <form
          onSubmit={handleSubmit}
          className="bg-white p-8 rounded shadow-md w-full max-w-[80%] m-4 flex flex-col justify-center items-center"
        >
          <h1 className="text-2xl font-bold mb-6 text-center">
            Select Keywords
          </h1>
          <div className="mb-4">
            <KeywordList
              keywords={keywords}
              onKeywordChange={handleKeywordChange}
            />
          </div>
          <button
            type="submit"
            className="w-[20%] bg-blue-500 text-white p-3 rounded"
          >
            Submit
          </button>
        </form>
      )}
    </div>
  );
};

export default KeywordSelectionPage;
