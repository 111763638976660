import React, { useEffect, useState } from "react";

interface ProgressWebSocketProps {
  spiderId: string;
  setScrapingInProgress: (scrapingInProgress: boolean) => void;
}

function sleep(ms: any) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const ProgressWebSocket: React.FC<ProgressWebSocketProps> = ({
  spiderId,
  setScrapingInProgress,
}) => {
  const [progress, setProgress] = useState<number>(0); // State to track progress
  const [status, setStatus] = useState<string>("Connecting...");
  const [error, setError] = useState<string>();

  useEffect(() => {
    const ws = new WebSocket(
      `wss://bcu.ideacore.com:8445/spider/progress/${spiderId}`
    );

    ws.onopen = () => {
      console.log("WebSocket connection opened");
      setStatus("Connected");
    };

    ws.onmessage = (event) => {
      // console.log("Message from server: ", event.data);

      if (event.data.includes("Progress")) {
        const progressValue = parseInt(event.data.replace("Progress: ", ""));
        setProgress(progressValue);
      } else if (event.data === "Task completed") {
        setProgress(100);
        setStatus("Task completed!");
        setScrapingInProgress(false);
        ws.close(); // Close the connection when the task is done
      } else if (event.data === "Error") {
        setError("We are sorry, an error occurred while checking the website.");
        ws.close();
      }
    };

    ws.onclose = () => {
      console.log("WebSocket connection closed");
      setStatus("Connection closed");
    };

    ws.onerror = (error) => {
      console.error("WebSocket error: ", error);
      setStatus("Error occurred while searching website");
    };

    // Clean up the WebSocket connection on component unmount
    return () => {
      if (ws.readyState === WebSocket.OPEN) {
        ws.close();
      }
    };
    // sleep(5000).then(() => {
    //   setProgress(100);
    //   setStatus("Task completed!");
    //   setScrapingInProgress(false);
    // });
  }, [spiderId]);

  return (
    <div>
      {error ? (
        <div className="flex items-center justify-center h-screen">
          <h1 className="text-red-600 text-2xl font-bold">{error}</h1>
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center h-screen">
          <div className="relative">
            {/* Circular progress background */}
            <div className="w-28 h-28 rounded-full border-4 border-gray-300"></div>
            {/* Circular progress overlay */}
            <svg className="absolute top-0 left-0 w-28 h-28 transform -rotate-90">
              <circle
                cx="50%"
                cy="50%"
                r="45"
                stroke="blue"
                strokeWidth="4"
                fill="none"
                strokeDasharray={283}
                strokeDashoffset={283 - (283 * progress) / 100}
                style={{
                  transition: "stroke-dashoffset 0.5s ease",
                }}
              />
            </svg>
            {/* Progress number in the center */}
            <div className="absolute inset-0 flex items-center justify-center text-2xl font-bold text-blue-600">
              {progress}%
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProgressWebSocket;
